import React from "react"
import { Link } from "gatsby"
import navData from "../../content/navigation.yml"

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="footer">
      <ul className="nav-links">
        {navData && navData.footer && navData.footer.map(item => (
          <li key={item.url}>
            <Link to={item.url}>{item.title}</Link>
          </li>
        ))}
        <li className="copyright">&copy; {year} Primoris Academy Inc. All rights reserved.</li>
      </ul>
      <div style={{flexGrow: 1}}></div>
      <div className="socials">
        <a href="http://www.facebook.com/PrimorisAcademyOrg" title="facebook" target="_blank" rel="noreferrer">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="140 -189 15.5 30">
            <path d="M150.1-159v-13.7h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6h2.8v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2c-4.1,0-6.9,2.5-6.9,7v4
              H140v5.3h4.6v13.7H150.1z"/>
          </svg>
        </a>
        <a href="http://instagram.com/primorisacademy" title="instagram" target="_blank" rel="noreferrer">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-346 292 30 30">
          <path d="M-316.1,300.8c-0.1-1.6-0.3-2.7-0.7-3.6c-0.4-1-0.9-1.8-1.7-2.7c-0.8-0.8-1.7-1.3-2.7-1.7c-1-0.4-2-0.6-3.6-0.7
            c-1.6-0.1-2.1-0.1-6.2-0.1s-4.6,0-6.2,0.1c-1.6,0.1-2.7,0.3-3.6,0.7c-1,0.4-1.8,0.9-2.7,1.7c-0.8,0.8-1.3,1.7-1.7,2.7
            c-0.4,1-0.6,2-0.7,3.6c-0.1,1.6-0.1,2.1-0.1,6.2s0,4.6,0.1,6.2c0.1,1.6,0.3,2.7,0.7,3.6c0.4,1,0.9,1.8,1.7,2.7
            c0.8,0.8,1.7,1.3,2.7,1.7c1,0.4,2,0.6,3.6,0.7c1.6,0.1,2.1,0.1,6.2,0.1s4.6,0,6.2-0.1c1.6-0.1,2.7-0.3,3.6-0.7
            c1-0.4,1.8-0.9,2.7-1.7c0.8-0.8,1.3-1.7,1.7-2.7c0.4-1,0.6-2,0.7-3.6c0.1-1.6,0.1-2.1,0.1-6.2S-316,302.4-316.1,300.8z
             M-318.8,313.1c-0.1,1.5-0.3,2.3-0.5,2.8c-0.3,0.7-0.6,1.2-1.1,1.7c-0.5,0.5-1,0.9-1.7,1.1c-0.5,0.2-1.3,0.5-2.8,0.5
            c-1.6,0.1-2.1,0.1-6.1,0.1s-4.5,0-6.1-0.1c-1.5-0.1-2.3-0.3-2.8-0.5c-0.7-0.3-1.2-0.6-1.7-1.1c-0.5-0.5-0.9-1-1.1-1.7
            c-0.2-0.5-0.4-1.3-0.5-2.8c-0.1-1.6-0.1-2.1-0.1-6.1s0-4.5,0.1-6.1c0.1-1.5,0.3-2.3,0.5-2.8c0.3-0.7,0.6-1.2,1.1-1.7
            c0.5-0.5,1-0.8,1.7-1.1c0.5-0.2,1.3-0.5,2.8-0.5c1.6-0.1,2.1-0.1,6.1-0.1s4.5,0,6.1,0.1c1.5,0.1,2.3,0.3,2.8,0.5
            c0.7,0.3,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7c0.2,0.5,0.5,1.3,0.5,2.8c0.1,1.6,0.1,2.1,0.1,6.1S-318.7,311.5-318.8,313.1z
             M-331,299.3c-4.3,0-7.7,3.4-7.7,7.7s3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7S-326.7,299.3-331,299.3z M-331,312c-2.8,0-5-2.2-5-5
            s2.2-5,5-5s5,2.2,5,5S-328.2,312-331,312z M-323,297.2c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8
            C-321.2,298-322,297.2-323,297.2z"/>
          </svg>
        </a>
        <a href="https://twitter.com/primorisacademy" title="twitter" target="_blank" rel="noreferrer">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-364 311 32 26">
            <path d="M-332,314.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6c-1.2-1.3-2.9-2.1-4.7-2.1
              c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5c-5.5-0.3-10.3-2.9-13.5-6.9c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5
              c-1.1,0-2.1-0.3-3-0.8v0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1c0.8,2.6,3.3,4.5,6.1,4.6
              c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1c2.9,1.9,6.4,2.9,10.1,2.9c12.1,0,18.7-10,18.7-18.7c0-0.3,0-0.6,0-0.8
              C-334,316.5-332.9,315.4-332,314.1z"/>
          </svg>
        </a>
      </div>
    </footer>
  );
};

export default Footer;